import type { CountryCode } from 'libphonenumber-js'
import { isPossiblePhoneNumber } from 'libphonenumber-js'
import type { ParsedQuery } from 'query-string'
import queryString from 'query-string'
import type { ZodError } from 'zod'

import type { User } from '@db'
import type { ActionFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { useRouteLoaderData } from '@remix-run/react'

const DEFAULT_REDIRECT = '/'

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(to: string) {
  if (!to || typeof to !== 'string') {
    return DEFAULT_REDIRECT
  }

  if (!to.startsWith('/') || to.startsWith('//')) {
    return DEFAULT_REDIRECT
  }

  return to
}

function isUser(user: any): user is User {
  return user && typeof user === 'object' && typeof user.email === 'string'
}

export function useOptionalUser(): User | undefined {
  try {
    const data = useRouteLoaderData('root') as { user: User | undefined }
    if (!data || !isUser(data.user)) {
      console.log('\n when no data return undefine...')
      return undefined
    }

    return data.user
  } catch (err: any) {
    console.log('\n error in use optional...', err.message || err)
  }
}

export function useUser(): User {
  const maybeUser = useOptionalUser()
  if (!maybeUser) {
    throw new Error(
      'No user found in root loader, but user is required by useUser. If user is optional, try useOptionalUser instead.'
    )
  }
  return maybeUser
}

export function validatePhoneNumber(phone: {
  number?: string
  country?: string
}) {
  if (typeof phone.number !== 'string' || typeof phone.country !== 'string')
    return false
  return isPossiblePhoneNumber(phone.number, phone.country as CountryCode)
}

export function sleep(duration: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

/**
 * Useful to create guard based on webhook sent from Hasura server
 */
export function HasuraInsertGuard(fn: ActionFunction): ActionFunction {
  return function (args) {
    if (args.request.method !== 'POST') {
      return json({ message: 'Method not allowed' }, 405)
    }
    if (
      args.request.headers.get('Hasura-Remix-Shared-Secret') !==
      process.env.HASURA_REMIX_SHARED_SECRET
    ) {
      return json({ message: 'Unauthorized' }, 401)
    }
    return fn(args)
  }
}

export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

// Check if there is an error for a specific path.
export function errorAtPath(error: ZodError, path: string) {
  return error.issues.find((issue) => issue.path[0] === path)?.message
}

export function isRejected<T>(
  val: PromiseSettledResult<T>
): val is PromiseRejectedResult {
  return val.status === 'rejected'
}

export function isFulfilled<T>(
  val: PromiseSettledResult<T>
): val is PromiseFulfilledResult<T> {
  return val.status === 'fulfilled'
}

function matched<T>(x: T) {
  return {
    on: () => matched(x),
    otherwise: () => x,
  }
}

type Pred<T> = (x: T) => Boolean
type Result<T> = (x: T) => any
export function match<T>(x: T) {
  return {
    on: (pred: Pred<T>, fn: Result<T>) =>
      pred(x) ? matched<T>(fn(x)) : match(x),
    otherwise: (fn: Result<T>) => fn(x),
  }
}

export function getSocialMetas({
  url,
  title = '',
  description = '',
  image = '',
  keywords = '',
}: {
  image?: string
  url: string
  title?: string
  description?: string
  keywords?: string
}) {
  return {
    title,
    description,
    keywords,
    image,
    'og:url': url,
    'og:title': title,
    'og:description': description,
    'og:image': image,
    'og:type': 'website',
    'twitter:card': image ? 'summary_large_image' : 'summary',
    'twitter:creator': '',
    'twitter:site': '',
    'twitter:title': title,
    'twitter:description': description,
    'twitter:image': image,
    'twitter:alt': title,
  }
}

export function getAppEnv(isClient?: boolean) {
  const context =
    isClient && typeof document !== 'undefined' ? 'Client' : 'Server'

  // Check if running on the client
  if (context === 'Client') {
    if (window.location.host.includes('localhost')) return 'development'
    // if (NODE_ENV === 'test') return 'test'
    if (window.location.host.includes('staging')) return 'staging'

    return 'production'
  }

  // Running on the server
  const NODE_ENV = process.env.NODE_ENV
  if (NODE_ENV === 'development') return 'development'
  if (NODE_ENV === 'test') return 'test'
  //@ts-expect-error
  if (NODE_ENV === 'staging') return 'staging'
  if (NODE_ENV === 'production') {
    // Extra check if running on a production server but the actual host name includes staging
    if (process.env.REMIX_HOST?.includes('staging')) return 'staging'
    return 'production'
  }
  return 'production'
}

/**
 * This will expose all variables within global ENV
 * Only for client-side env
 * Never expose the SESSION_SECRET or any server/node/non-browser env
 * @url https://github.com/mhaidarhanif/rewinds/blob/main/app/utils/env.server.ts
 */
export function getClientEnv() {
  return {
    APP_ENV: getAppEnv(true),
    SENTRY_DSN: process.env.SENTRY_DSN,
  }
}

type ParserFunction = (params: URLSearchParams) => ParsedQuery
export const customParser: ParserFunction = (params) => {
  const parsed = queryString.parse(params.toString())
  return parsed
}

export const formatPrice = (value: number) => {
  try {
    let USDollar = new Intl.NumberFormat()
    return USDollar.format(value)
  } catch (err) {
    return value
  }
}

// export const allSampleVoice = [
//   {
//     name: 'Friendly Voice',
//     originalName: 'Neural2 A',
//     voice: 'en-AU-Neural2-A',
//     gender: 'Female',
//     path: '/voices/en-AU-Neural2-A-female.mp3',
//   },
//   {
//     name: 'Friendly Voice',
//     originalName: 'Neural2 B',
//     voice: 'en-AU-Neural2-B',
//     gender: 'Male',
//     path: '/voices/en-AU-Neural2-B-male.mp3',
//   },
//   {
//     name: 'Mature Friendly Voice',
//     originalName: 'Neural2 C',
//     voice: 'en-AU-Neural2-C',
//     gender: 'Female',
//     path: '/voices/en-AU-Neural2-C-female.mp3',
//   },
//   {
//     name: 'Mature Friendly Voice',
//     originalName: 'Neural2 D',
//     voice: 'en-AU-Neural2-D',
//     gender: 'Male',
//     path: '/voices/en-AU-Neural2-D-male.mp3',
//   },
//   {
//     name: 'Down to Earth Voice',
//     originalName: 'News E',
//     voice: 'en-AU-News-E',
//     gender: 'Female',
//     path: '/voices/en-AU-News-E-female.mp3',
//   },
//   {
//     name: 'Down to Earth Higher Voice',
//     originalName: 'News F',
//     voice: 'en-AU-News-F',
//     gender: 'Female',
//     path: '/voices/en-AU-News-F-female.mp3',
//   },
//   {
//     name: 'Down to Earth Voice',
//     originalName: 'News G',
//     voice: 'en-AU-News-G',
//     gender: 'Male',
//     path: '/voices/en-AU-News-G-male.mp3',
//   },
//   {
//     name: 'Down to Earth Higher Voice',
//     originalName: 'Polyglot 1',
//     voice: 'en-AU-Polyglot-1',
//     gender: 'Male',
//     path: '/voices/en-AU-Polyglot-1-male.mp3',
//   },
//   {
//     name: 'Young Friendly Voice',
//     originalName: 'Standard A',
//     voice: 'en-AU-Standard-A',
//     gender: 'Female',
//     path: '/voices/en-AU-Standard-A-female.mp3',
//   },
//   {
//     name: 'Young Friendly Voice',
//     originalName: 'Standard B',
//     voice: 'en-AU-Standard-B',
//     gender: 'Male',
//     path: '/voices/en-AU-Standard-B-male.mp3',
//   },
//   {
//     name: 'Professional Voice',
//     originalName: 'Standard C',
//     voice: 'en-AU-Standard-C',
//     gender: 'Female',
//     path: '/voices/en-AU-Standard-C-female.mp3',
//   },
//   {
//     name: 'Professional Voice',
//     originalName: 'Standard D',
//     voice: 'en-AU-Standard-D',
//     gender: 'Male',
//     path: '/voices/en-AU-Standard-D-male.mp3',
//   },
//   {
//     name: 'Higher Professional Voice',
//     originalName: 'Wavenet A',
//     voice: 'en-AU-Wavenet-A',
//     gender: 'Female',
//     path: '/voices/en-AU-Wavenet-A-female.mp3',
//   },
//   {
//     name: 'Higher Professional Voice',
//     originalName: 'Wavenet B',
//     voice: 'en-AU-Wavenet-B',
//     gender: 'Male',
//     path: '/voices/en-AU-Wavenet-B-male.mp3',
//   },
//   {
//     name: 'Professional Voice',
//     originalName: 'Wavenet C',
//     voice: 'en-AU-Wavenet-C',
//     gender: 'Female',
//     path: '/voices/en-AU-Wavenet-C-female.mp3',
//   },
//   {
//     name: 'Professional Voice',
//     originalName: 'Wavenet D',
//     voice: 'en-AU-Wavenet-D',
//     gender: 'Male',
//     path: '/voices/en-AU-Wavenet-D-male.mp3',
//   },
// ]

export const allSampleVoice = [
  {
    name: 'Arabella - A young engaging female voice',
    voice: 'aEO01A4wXwd1O8GPgGlF',
    gender: 'Female',
    path: '/elevenlabs/arabella.mp3',
  },
  {
    name: 'Sophia - A young bright voice',
    voice: 'LtPsVjX1k0Kl4StEMZPK',
    gender: 'Female',
    path: '/elevenlabs/sophia.mp3',
  },
  {
    name: 'Jason Pike - A friendly and educated voice',
    voice: 'mkrzc6Zmz8alRK0wX5dd',
    gender: 'Male',
    path: '/elevenlabs/jason-pike.mp3',
  },
  {
    name: 'Amelia - Young Australian Female voice',
    voice: 'nBoLwpO4PAjQaQwVKPI1',
    gender: 'Female',
    path: '/elevenlabs/amelia.mp3',
  },
  {
    name: 'Lee Middle-A professional male voice',
    voice: 'abRFZIdN4pvo8ZPmGxHP',
    gender: 'Male',
    path: '/elevenlabs/lee-middle.mp3',
  },
  {
    name: 'Arthur - Young Male Aussie Guy',
    voice: 'wlIlYogpgwJSyPL6RhoA',
    gender: 'Male',
    path: '/elevenlabs/arthur.mp3',
  },
  {
    name: 'Emily - Smooth Young Australian Voice',
    voice: 'p43fx6U8afP2xoq1Ai9f',
    gender: 'Female',
    path: '/elevenlabs/emily.mp3',
  },
  {
    name: 'Beth - Older woman voice',
    voice: '4gNKbEbHZ4K1SAI5K1wh',
    gender: 'Female',
    path: '/elevenlabs/beth.mp3',
  },
  {
    name: 'Ava - Young female with a slight accent',
    voice: 'SyM9jzWJNXvjnPvB3HXQ',
    gender: 'Female',
    path: '/elevenlabs/ava.mp3',
  },
  {
    name: 'Aiden - The Irresistible Aussie Charmer',
    voice: 'kmgHxbD6paT09Ergj3XM',
    gender: 'Male',
    path: '/elevenlabs/aiden.mp3',
  },
  {
    name: 'Jason Jordan - A neutral, deep voice',
    voice: 'D9oXHIyU6iZzDJNymrTO',
    gender: 'Male',
    path: '/elevenlabs/jason-jordan.mp3',
  },
  {
    name: 'Ellie - A young soft spoken female voice',
    voice: 'Hgfor6xcJTM3hCSKmChL',
    gender: 'Female',
    path: '/elevenlabs/ellie.mp3',
  },
  {
    name: 'Steve - Australian Male Professional',
    voice: 'aGkVQvWUZi16EH8aZJvT',
    gender: 'Male',
    path: '/elevenlabs/steve.mp3',
  },
  {
    name: 'Brooky Serenity - Gravelly mature male',
    voice: 'cdZvTWMWZZID3MSEDICu',
    gender: 'Male',
    path: '/elevenlabs/brooky-serenity.mp3',
  },
  {
    name: 'Jacob Dayi - Bright male voice',
    voice: 'KHx6YfZBu23HH6GJtSrW',
    gender: 'Male',
    path: '/elevenlabs/jacob-dayi.mp3',
  },
  {
    name: 'Paul - Australian Professional Presenter',
    voice: 'WLKp2jV6nrS8aMkPPDRO',
    gender: 'Male',
    path: '/elevenlabs/paul.mp3',
  },
  {
    name: 'Darren - A deep Australian male voice',
    voice: 'wV48jEcOtQocLt7P2zIy',
    gender: 'Male',
    path: '/elevenlabs/darren.mp3',
  },
  {
    name: 'Stuart - Energetic and enthusiastic',
    voice: 'HDA9tsk27wYi3uq0fPcK',
    gender: 'Male',
    path: '/elevenlabs/stuart.mp3',
  },
  {
    name: 'Ben - Youthful adult Australian male',
    voice: 'sai9UY7iXkRDSsXHR0bZ',
    gender: 'Male',
    path: '/elevenlabs/ben.mp3',
  },
  {
    name: 'Maya - A young Australian female voice',
    voice: 'MiueK1FXuZTCItgbQwPu',
    gender: 'Female',
    path: '/elevenlabs/maya.mp3',
  },
  {
    name: 'Rachel McGrath - Older calm female voice',
    voice: 'paRTfYnetOrTukxfEm1J',
    gender: 'Female',
    path: '/elevenlabs/rachel-mcgrath.mp3',
  },
  {
    name: 'Benjamin S Powell - Gentle tone',
    voice: 'sclx1MZrNqboRcmLWoDb',
    gender: 'Male',
    path: '/elevenlabs/benjamin-s-powell.mp3',
  },
  {
    name: 'Vivian - Young Female with Asian background',
    voice: 'luVEyhT3CocLZaLBps8v',
    gender: 'Female',
    path: '/elevenlabs/vivian.mp3',
  },
  {
    name: 'Steven Centofanti - Great upbeat clarity',
    voice: 'FoD619dn9b25wYA1kTvP',
    gender: 'Male',
    path: '/elevenlabs/steven-centofanti.mp3',
  },
  {
    name: 'Aussie Margot - Warm and sweet',
    voice: 'uvxDZyxRISyWYoLZ1r0W',
    gender: 'Female',
    path: '/elevenlabs/aussie-margot.mp3',
  },
];