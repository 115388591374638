import { useState, useEffect, useRef } from 'react'
import { AiOutlineClose } from 'react-icons/ai'
import { GiHamburgerMenu } from 'react-icons/gi'

import { Link, useMatches, useRouteLoaderData } from '@remix-run/react'

import type { GetAuthenticatedUserResponse } from '~/models/user.server'

export default function NavBar() {
  const [logoUrl, setLogoUrl] = useState<string | null>(null)
  const dropdownRef = useRef(null);
  const matches = useMatches()
  const { user } = useRouteLoaderData('root') as {
    user?: GetAuthenticatedUserResponse
  }

  const [showPanel, setShowPanel] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };
  useEffect(() => {
    try {
      if (!user) return

      /* 
      TODO: for user profile
      if (user?.clients && user.clients.length > 0) {
        if (user.clients[0].logoImage?.secureUrl) {
          setLogoUrl(user.clients[0].logoImage?.secureUrl)
        }
      } */

    } catch (err) { }
  }, [user])

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      //@ts-ignore
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className="relative bg-primary px-4 md:px-10">
      <div className="flex items-center justify-between">
        <Link className="text-2xl leading-none  text-gray-600" to="/">
          <img
            className="h-12 my-3"
            src="/assets/logo/logo.svg"
            alt=""
            width="auto"
          />
        </Link>
        <div className="lg:hidden">
          <button
            className="navbar-burger hover:shadow-4xl block text-black focus:outline-none"
            onClick={() => setShowPanel((prevState) => !prevState)}
          >
            <GiHamburgerMenu size={20} />
          </button>
        </div>
        <ul className="hidden w-auto items-center  space-x-12 lg:flex">
          <li>
          <div id="google_translate_element"></div>
          </li>
          {user ? (
            <>
              {user.isAdmin && (
                <>
                  <li>
                    <Link
                      className="text-lg font-medium hover:text-gray-600"
                      to="/admin-campaigns"
                    >
                      All Campaigns
                    </Link>
                  </li>
                </>
              )}
              <li>
                <Link
                  className="text-lg font-medium hover:text-gray-600"
                  to="/campaigns"
                >
                  Creatives
                </Link>
              </li>
              <li>
                <img src={logoUrl ? logoUrl : "/assets/illustrations/logo.png"} alt="Logo"
                  className="prfleImg w-8 h-8 my-3 cursor-pointer rounded"
                  onClick={toggleDropdown}
                  ref={dropdownRef}
                />
              </li>
              {isDropdownOpen && (
                <div className="cstm_dropdown absolute mt-32 z-60 w-40 bg-white border border-gray-300 rounded-lg shadow-md">
                  <ul className="py-2">
                    <li>
                      <Link
                        to="/account"
                        className="block px-4 py-2 hover:bg-gray-200"
                        onClick={closeDropdown}
                      >
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/logout"
                        className="block px-4 py-2 hover:bg-gray-200"
                        onClick={closeDropdown}
                      >
                        Log out
                      </Link>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              {' '}
              <li>
                <Link
                  className="text-lg font-medium hover:text-gray-600"
                  to="/signup"
                >
                  Sign Up
                </Link>
              </li>
              <li>
                <Link
                  className="text-lg font-medium hover:text-gray-600"
                  to="/login"
                >
                  Login
                </Link>
              </li>
            </>
          )}
        </ul>
      </div>
      <div
        className={`${showPanel ? 'visible' : 'hidden'
          } animate-in  navbar-menu slide-in-from-left fixed top-0 left-0 bottom-0 z-50 w-5/6 max-w-sm duration-1000 z-[99]`}
      >
        <div className="navbar-backdrop fixed inset-0 bg-gray-800 opacity-25"></div>
        <nav className="relative flex h-full w-full flex-col overflow-y-auto border-r bg-primary py-6 px-6">
          <div className="mb-12 flex items-center">
            <Link
              className="mr-auto text-2xl font-semibold leading-none"
              to="/"
            >
              <img
                className="h-8"
                src="/assets/logo/logo.png"
                alt=""
                width="auto"
              />
            </Link>
            <button
              className="navbar-close"
              onClick={() => setShowPanel((prevState) => !prevState)}
            >
              <AiOutlineClose size={20} />
            </button>
          </div>
          <div>
            <ul>
              <li className="mb-1">
                <Link
                  className="block rounded p-4 text-lg font-semibold hover:bg-white hover:text-black"
                  to="/"
                >
                  Home
                </Link>
              </li>
            </ul>
          </div>
          <div className="mt-auto">
            <div className="pt-6">
              {user ? (
                <>
                  <Link
                    className="mb-3 block rounded border border-black px-5  py-3 text-center text-sm font-semibold text-black transition duration-200 hover:shadow-xl"
                    to="/campaigns"
                  >
                    Creatives
                  </Link>
                </>
              ) : (
                <>
                  <Link
                    className="block rounded border border-black px-5  py-3 text-center text-sm font-semibold text-black transition duration-200 hover:shadow-xl mb-2"
                    to="/login"
                  >
                    Login In
                  </Link>
                  <Link
                    className="block rounded border border-black px-5  py-3 text-center text-sm font-semibold text-black transition duration-200 hover:shadow-xl"
                    to="/signup"
                  >
                    Sign up
                  </Link>
                </>
              )}
            </div>
            <p className="mt-6 mb-4 text-center text-sm text-gray-500">
              <span>© Engage TV All rights reserved.</span>
            </p>
          </div>
        </nav>
      </div>
    </nav>
  )
}
